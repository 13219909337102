import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Web3ReactManager from "components/Web3ReactManager";
import HeaderSecond from "components/HeaderSecond";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/fonts/stylesheet.css";
import "assets/scss/style.scss";
import Popups from "components/Popups";

import StakePage from "pages/Stake";

import styled, { ThemeProvider } from "styled-components/macro";
import { lightTheme, GlobalStyles } from "./themes";
import { Buffer } from 'buffer';

// @ts-ignore
window.Buffer = Buffer;

const PageContainer = styled.div`
  display: flex;
  align-items: start;
  min-height: calc(100vh - 160px);
  background-color: #051014;
  padding-top: 1rem;
  padding-bottom: 1rem;
`


// eslint-disable-next-line
function App() {
  return (
    <Web3ReactManager>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />
        <Router>
          <HeaderSecond />
          <PageContainer>
            <Routes>
              <Route path="/" element={<StakePage />} />
            </Routes>
          </PageContainer>
          {/* <Popups /> */}
        </Router>
      </ThemeProvider>
    </Web3ReactManager>
  );
}

export default App;
