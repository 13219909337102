import styled from 'styled-components/macro'

export const Column = styled.div`
    width: 95%;
    margin: 0 auto;

    @media(min-width: 768px) {
        width: 90%;
    }

    @media(min-width: 992px) {
        width: 80%;
    }

    @media(min-width: 1200px) {
        width: 70%;
    }
`

export const SectionWrapper = styled.div`
    // display: flex;
    // flex-direction: column;
    // justify-content: start;
    // align-items: center;
    //padding: 5rem 1rem 2rem;
    background-color: transparent;
    // gap: 1rem;

    // @media (min-width: 992px) {
    //     flex-direction: row;
    //     justify-content: center;
    //     align-items: start;
    // }
`
export const ContentWrapper = styled.div`
    padding: 1rem;
    border-radius: 30px;
    background: #040D11;
    border: 1px solid #11292F;
    margin-top: 2rem;
`
export const Title = styled.p`
    font-family: Roboto Mono;
    font-weight: 600;
    font-size: 18px;
    color: #fff;

    .claimable {
        font-family: Roboto Mono;
        font-weight: 300;
        font-size: 16px;
        color: #00dd72;
    }

    .pending {
        font-family: Roboto Mono;
        font-weight: 300;
        font-size: 16px;
        color: red;
    }
`
export const Balance = styled.p`
    font-family: Roboto Mono;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
`
export const LabelText = styled.p`
    font-family: Roboto Mono;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    margin-bottom: 5px;
`
export const InputStyled = styled.input`
    width: 90%;
    border-radius: 12px;
    font-size: 16px;
    color: #fff;
    font-family: Roboto Mono;
    border: none;
    background-color: transparent;

    &:focus {
        outline: none;
    }
`
export const MaxButton = styled.button`
    background-color: #00dd72;
    color: #000;
    font-family: Roboto Mono;
    border-radius: 12px;
    padding: 8px 12px;
    border: none;
    font-size: 12px;
    font-weight: 600;
`
export const ButtonStyled = styled.button`
    width: 100%;
    background-color: #00dd72;
    color: #051014;
    font-family: Roboto Mono;
    height: 3rem;
    border: none;
    border-radius: 12px;
    margin-top: 1.5rem;
    font-weight: 600;

    &:disabled {
      background-color: #0A1619;
      color: #FFFFFF80;
    }
`
export const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media(min-width: 992px) {
        margin-bottom: 0rem;
    }
`
export const RowBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const InputDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #11292F;
    background: #0A1619;
    height: 3rem;
    padding: 0.5rem 1rem;
`
export const RowCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
export const RewardBox = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2.5%;
    padding: 0.5rem;
    border-radius: 30px;
    background: #040D11;
    border: 1px solid #11292F;
    margin-top: 2rem;
`
export const RewardItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 2rem;
    border-radius: 30px;
    background: #040D11;
    border: 1px solid #11292F;
    margin-top: 0.5rem;

    @media (min-width: 768px) {
        width: 48%;
        margin-top: 0;
    }
`
export const ColumnResponsive = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
        display: block;
        //align-items: flex-start;
    }
`
export const StatisticTitle = styled.p`
    font-family: Roboto Mono;
    font-size: 16px;
    font-weight: 300;
    line-height: 21.1px;
    text-align: center;
    color: #626570;
`
export const StatisticContent = styled.p`
    font-family: Roboto Mono;
    font-size: 28px;
    font-weight: 300;
    line-height: 36.93px;
    text-align: center;
    color: #00DC70;
`
export const LinkSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    @media (min-width: 768px) {
        display: flex;
        flex-direction: row !important;
        justify-content: space-between !important;
        align-items: flex-start !important;
    }
`
export const Link = styled.a`
    font-family: Roboto Mono;
    font-size: 14px;
    font-weight: 300;
    line-height: 18.46px;
    text-align: left;
    color: #fff;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: #00DC70;
    }
`
export const SocialBox = styled.div`
    width: 8.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
    background: #081316;
    padding: 1rem;
    margin-top: 2rem;

    div {
        width: 0.5px;
        height: 15px;
        background: #fff;
    }

    a {
        cursor: pointer;
    }
`
export const Divider = styled.div`
    width: 100%;
    height: 0.5px;
    background: 11292F; 

    @media (min-width: 768px) {
        width: 0.5px;
        height: 5rem;
    }
`