import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import {
  ButtonWrapper,
  HeaderMain,
  Column,
  HeaderWrapper,
  TitleWapper,
  MenuToggle,
  NavBarWrap,
  NavLink,
  NavLink2,
  StyledWalletIcon,
  HeaderMobile,
  MobileLogo,
  StakedLink
} from "./Styled";
import ConnectButton from "components/ConnectButton";
import { FiMenu } from "react-icons/fi";
import Logo from 'assets/images/Header-logo.svg'

function HeaderSecond(): JSX.Element {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  return (
    <HeaderMain>
      <Column>
        <HeaderWrapper>
          <img src={Logo} />
          <NavBarWrap className="d-flex justify-content-end flex-grow-1">
            {/* <ul className="main-menu list-unstyled">
              <li>
                <NavLink to="/undelagate-stake">Undelegate</NavLink>
              </li>
              <li>
                <NavLink to="/stake">Staking</NavLink>
              </li>
              <li>
                <NavLink to="/termsofservice">Terms of service</NavLink>
              </li>
            </ul> */}
            <StakedLink href="https://shiboo-1.gitbook.io/shiboo-lp-staking" target="_blank">
                How to stake
              </StakedLink>
            {/* <Button  handleClick={() => console.log('manolo') }> HOW TO STAKE </Button> */}
            <ButtonWrapper>
              <ConnectButton />
            </ButtonWrapper>
          </NavBarWrap>
        </HeaderWrapper>
      </Column>
    </HeaderMain>
  );
}

export default HeaderSecond;
