import { useCallback } from 'react'
import getConfig from 'config/config'
import axios from 'axios'

export const useBalance = (account: string | null, contract: string | null): (() => Promise<any>) => {
  return useCallback(async (): Promise<any> => {
    try {
      if (!account || !contract) {
        return
      }
      const apiURL = getConfig().stakeAPIURL
      const lpToken = getConfig().lpAddress 
      const res = await axios.get(`${apiURL}/cep18Balance/${lpToken}/${account}`)
      console.log('sljskfjkasjfkas', res.data.balance)
      if (res.data.balance) {
        return res.data.balance
      }
      return '0'
    } catch (error: any) {
      console.error(`error ${account} ${contract} ${error}`)
      return '0'
    }
  }, [account, contract])
}