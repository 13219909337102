import Network from "type/Network";
import BigNumber from "bignumber.js";
import { ConnectorNames } from "connectors";
import {
  CLPublicKey,
  CasperClient,
  DeployUtil,
  CasperServiceByJsonRPC,
  GetDeployResult,
} from "casper-js-sdk";
import { SafeEventEmitterProvider } from "casper-js-sdk/dist/services/ProviderTransport";
import getConfig from "config/config";
import axios from "axios";

export const isDev = process.env.REACT_APP_IS_TESTNET === "true";

export const getExplorerLink = (
  data: string,
  type: "transaction" | "token" | "address" | "block" | "contract"
): string => {
  const prefix = `${getConfig().explorerURL}`;
  switch (type) {
    case "transaction": {
      return `${prefix}/deploy/${data}`;
    }
    case "token": {
      return `${prefix}/token/${data}`;
    }
    case "block": {
      return `${prefix}/block/${data}`;
    }
    case "contract": {
      return `${prefix}/contract/${data}`;
    }
    case "address":
    default: {
      return `${prefix}/account/${data}`;
    }
  }
};

export const ipfsURLConvert = (url: string) => {
  try {
    return url.replace("ipfs://", "https://ipfs.io/ipfs/");
  } catch (e) {
    return "";
  }
};

export const genRanHex = (size = 64) => {
  return [...Array(size)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join("");
};

export const isCacheOutdated = (): boolean => {
  const key = "casperpunk-lastupdated";
  const lastUpdated = localStorage.getItem(key);
  if (lastUpdated !== undefined) {
    const now = Date.now();
    const diff = now - Number(lastUpdated);

    if (diff > 15 * 60 * 1000) {
      localStorage.setItem(key, now.toString());
      return true;
    }

    return false;
  }

  return true;
};

export const trimName = (
  name: string,
  start = 18,
  end = 16,
  total = 40
): string => {
  if (name?.length > total) {
    return `${name.substring(0, start)}...${name.substring(name.length - end)}`;
  } else {
    return name;
  }
};

export function formatWithCommas(value: string): string {
  const pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(value)) {
    value = value.replace(pattern, "$1,$2");
  }
  return value;
}

export const toPrecision = (
  number: string,
  precision: number,
  withCommas = false,
  atLeastOne = true
): string => {
  const [whole, decimal = ""] = number.split(".");

  let str = `${withCommas ? formatWithCommas(whole) : whole}.${decimal.slice(
    0,
    precision
  )}`.replace(/\.$/, "");
  if (atLeastOne && Number(str) === 0 && str.length > 1) {
    const n = str.lastIndexOf("0");
    str = str.slice(0, n) + str.slice(n).replace("0", "1");
  }

  return str;
};

export default function formatNumber(num, precision = 2) {
  const map = [
    { suffix: "T", threshold: 1e12 },
    { suffix: "B", threshold: 1e9 },
    { suffix: "M", threshold: 1e6 },
    { suffix: "K", threshold: 1e3 },
    { suffix: "", threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return num;
}

export const toReadableNumber = (decimals: number, number = "0"): string => {
  if (!decimals) return number;
  if (number?.length == 0 || number == null) return "0";
  const wholeStr = number.substring(0, number.length - decimals) || "0";
  const fractionStr = number
    .substring(number.length - decimals)
    .padStart(decimals, "0")
    .substring(0, decimals);

  return `${wholeStr}.${fractionStr}`.replace(/\.?0+$/, "");
};

export const toRoundedReadableNumber = ({
  decimals,
  number = "0",
  precision = 6,
  withCommas = true,
}: {
  decimals: number;
  number?: string;
  precision?: number;
  withCommas?: boolean;
}): string => {
  return toPrecision(toReadableNumber(decimals, number), precision, withCommas);
};

export const formatPrice = (price: number | string): string | number => {
  if (price) {
    return Number.isInteger(Number(price)) ? price : Number(price).toFixed(3);
  } else {
    return 0;
  }
};

export const niceDecimals = (number: string | number, precision = 2) => {
  const str = number.toString();
  const [whole, decimals] = str.split(".");
  if (!decimals || Number(decimals) == 0) {
    return whole;
  } else {
    return new BigNumber(number).toFixed(precision, 1);
  }
};

export const toInternationalCurrencySystemNature = (
  labelValue: string,
  percent?: number
) => {
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? new BigNumber(Math.abs(Number(labelValue)) / 1.0e9).toFixed(
        percent || 2,
        1
      ) + "B"
    : Math.abs(Number(labelValue)) >= 1.0e6
    ? new BigNumber(Math.abs(Number(labelValue)) / 1.0e6).toFixed(
        percent || 2,
        1
      ) + "M"
    : Math.abs(Number(labelValue)) >= 1.0e3
    ? new BigNumber(Math.abs(Number(labelValue)) / 1.0e3).toFixed(
        percent || 2,
        1
      ) + "K"
    : niceDecimals(labelValue);
};

export const toCSPR = (price: string | undefined | null): string => {
  if (price) {
    return new BigNumber(price).div(1e9).toString();
  } else {
    return "0";
  }
};

export const toDecimalsToken = (
  amount: string | undefined | null,
  decimals: number
): string => {
  try {
    if (amount) {
      amount = new BigNumber(amount)
        .dividedBy(10 ** decimals)
        .toFixed(decimals)
        .toString();
      if (parseFloat(amount) == 0) return "0";
      else return amount;
    } else {
      return "0";
    }
  } catch (e) {
    console.warn("TO DECIMALS", e);
    return "0";
  }
};

export function capitalizeFirstLetter(string) {
  try {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } catch {
    return "";
  }
}

export const getDeployFunction = async (
  account: string,
  casperClient: CasperClient,
  connectorId: string,
  deploy: any,
  provider: any,
  json: any,
  connector: any,
): Promise<any> => {
  let signature: any = undefined;
  let deployObject: any = undefined;
  let deployFn: any = undefined;

  if (
    connectorId === ConnectorNames.CasperSigner ||
    connectorId === ConnectorNames.CasperDash
  ) {
    signature = await provider.sign(json, account, account);
    console.log("signature: ", signature);
    const _deploy = DeployUtil.deployFromJson(signature);
    deployObject = _deploy.val;
    if (deployObject instanceof DeployUtil.Deploy) {
      deployFn = casperClient.putDeploy(deployObject);
    }
  } else if (connectorId === ConnectorNames.CasperWallet) {
    signature = await provider.sign(JSON.stringify(json), account);
    deployObject = DeployUtil.setSignature(
      deploy,
      signature.signature,
      CLPublicKey.fromHex(account)
    );
    deployFn = casperClient.putDeploy(deployObject);
  } else {
    if (connector) {
      // @ts-ignore
      const { torus } = connector;
      const casperService = new CasperServiceByJsonRPC(
        torus?.provider as SafeEventEmitterProvider
      );
      deployFn = casperService.deploy(deploy);
    }
  }

  return deployFn;
};

export function formatString1(string: string, number = 10) {
  if (string) {
    // string = formatCurrencyString(parseFloat(string));
    const newString = string.slice(0, number);
    if (
      newString[newString.length - 1] == "." ||
      newString[newString.length - 1] == ","
    ) {
      const a = newString.slice(0, -1);
      return a;
    }
    return newString;
  }
  return string;
}

const FRACTIONAL_DIGITS = 5;

export const formatString = (amount: string, float = 7) => {
  try {
    if (parseFloat(amount) === 0) {
      return amount;
    }
    if (parseFloat(amount) < 0.00001) {
      return `< ${
        !FRACTIONAL_DIGITS
          ? "0"
          : `0.${"0".repeat((FRACTIONAL_DIGITS || 1) - 1)}1`
      }`;
    }

    return formatString1(amount, float);
  } catch {
    return "0";
  }
};

export const toBigNumber = (amount: string, decimals = 9) => {
  amount = parseFloat(amount).toFixed(decimals);
  return new BigNumber(amount).multipliedBy(10 ** decimals).toFixed();
};
export const serialize = (obj) => {
  const str: string[] = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};
export const formatCurrencyString = (number: number) => {
  number = number;
  return number.toLocaleString("en-US", { minimumFractionDigits: 2 });
};

//
export const getBalanceToken = (array, label) => {
  try {
    return array.filter((e) => e.label == label)[0].balance;
  } catch {
    return "0";
  }
};
export const getDecimalsToken = (array, label) => {
  try {
    return array.filter((e) => e.label == label)[0].decimals;
  } catch {
    return "0";
  }
};
export const getAmountReadable = (array, label) => {
  try {
    return toDecimalsToken(
      getBalanceToken(array, label),
      getDecimalsToken(array, label)
    );
  } catch {
    return "0";
  }
};

export const waitForDeployExecution = async (
  casperClient: CasperClient,
  deployHash: string,
  ticks = 1000
): Promise<[DeployUtil.Deploy, GetDeployResult]> => {
  let errorText;
  let i = 0;
  while (i !== ticks) {
    try {
      const [deploy, raw] = await casperClient.getDeploy(deployHash);
      if (raw.execution_results.length !== 0) {
        if (raw.execution_results[0].result.Success) {
          return [deploy, raw];
        } else {
          errorText =
            "Contract execution: " +
            raw.execution_results[0].result.Failure?.error_message;
          break;
        }
      } else {
        i++;
        await sleep(1000);
      }
    } catch (e) {
      i++;
      await sleep(1000);
    }
  }
  if (errorText) throw Error(errorText);
  throw Error("Timeout after " + i + "s. Something's wrong");
};
export const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export function toFixedCustom(num, fixed) {
  var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}

export const getUndelegatedAmount = async (account: string) => {
  let { data } = await axios.get(`${getConfig().makeAPI}/rpc/info_get_status`);
  const lastAddedBlockInfo = data.result.last_added_block_info;
  const eraId = lastAddedBlockInfo.era_id;
  const responseUndelegatedAmount = await axios.get(
    `${getConfig().makeAPI}/accounts/${account}/tokens-in-undelegation/${eraId}`
  );
  const undelegatedAmount = responseUndelegatedAmount.data.data;

  const _undelegatedAmount = new BigNumber(Number(undelegatedAmount))
    .dividedBy(10 ** 9)
    .toFixed(2);
  return _undelegatedAmount;
};

export const getStakedAmount = async (account: string) => {
  const responseStakeAmount = await axios.get(
    `${
      getConfig().makeAPI
    }/accounts/${account}/delegations?page=1&limit=-1&fields=account_info`
  );

  for (const stakedData of responseStakeAmount.data.data) {
    if (stakedData.validator_public_key == getConfig().validator) {
      const userStakedAmount = stakedData.stake;

      const _userStakedAmount = new BigNumber(Number(userStakedAmount))
        .dividedBy(10 ** 9)
        .toFixed(2);
      return _userStakedAmount;
    }
  }
  return 0;
};
//
