import { CgSpinner } from "react-icons/cg";
import styled, { keyframes } from "styled-components/macro";

export const StyledButton = styled.button<{ disabled: boolean | undefined }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00DC70;
  border: 1px solid #00DC70;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  color: #161f2f;
  font-weight: 700;
  font-size: 14px;
  font-family: Roboto Mono;
  line-height: 17px;
  text-transform: capitalize;
  transition: all 0.3s ease;

  svg {
    margin-right: 0.5rem;
  }

  // &:hover {
  //   background-color: ${({ disabled }) => (disabled ? "#fff" : "#00dd72")};
  //   border: 1px solid ${({ disabled }) => (disabled ? "#e5e5e5" : "#00dd72")};
  //   color: ${({ disabled }) => (disabled ? "#565660" : "#fff")};

  //   svg {
  //     fill: #fff;
  //   }
  // }
`;

export const ButtonPrimary = styled(StyledButton)<{
  padding?: string;
  bg?: string;
}>`
  background: #fff;
  border: none;
  padding: ${({ padding }) => padding ?? "0.5rem 3rem"};
  font-weight: 800;
  font-size: 17px;
  line-height: 27px;
  text-transform: capitalize;
  transition: all 0.3s ease;

  :disabled {
    opacity: 0.7;
  }

  span {
    color: #9a0d2f;
  }

  &:hover {
    background: #ad203e;
    transform: translateY(-0.5rem);
    border-color: #ad203e;
  }
`;

export const ButtonOutline = styled(StyledButton)<{ padding?: string }>`
  background: #ffffff;
  padding: ${({ padding }) => padding ?? "0.5rem 3rem"};
  border: 1px solid #ad203e;
  a {
    color: #ad203e;
  }
  span {
    color: #ad203e;
  }
  font-weight: 800;
  font-size: 17px;
  line-height: 27px;
  text-transform: capitalize;
  transition: all 0.3s ease;

  &:hover {
    a {
      color: white;
    }
    span {
      color: white;
    }
    background: #ad203e;
    border-color: #ad203e;
  }
`;

export const ButtonSecondary = styled(StyledButton)<{ padding?: string }>`
  background: #9a0d2f;
  padding: ${({ padding }) => padding ?? "1rem 3rem"};
  border: none;

  span {
    color: #fff;
  }

  &:hover {
    background: rgb(230, 51, 42);
    border: none;
    transform: translateY(-0.1rem);

    span {
      background: #fff;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

export const SubmitButton = styled(StyledButton)`
  background-color: #3749e9;
  width: 100%;
  padding: 10px 0;
  font-weight: 500;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  border: none;
  margin-top: 1.5rem;

  &:hover {
    background-color: #3749e9;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(CgSpinner)`
  animation: ${spin} 0.75s linear infinite;
  margin-right: 0.5rem;
`;
