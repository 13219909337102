
export default function getConfig(env: string | undefined = process.env.REACT_APP_NETWORK) {
  switch (env) {
    case 'testnet':
      return {
        network: 'casper-test',
        chainId: 96945816564243,
        explorerURL: 'https://testnet.cspr.live',
        nodeURL: 'https://testnet.casper-node.tor.us',
        makeAPI: 'https://event-store-api-clarity-testnet.make.services',
        validator: '017d9aa0b86413d7ff9a9169182c53f0bacaa80d34c211adab007ed4876af17077',
        minStake: 0,
        contracts: {
          stakingContract: 'f3e10270b5b2bc41384bfab66c7061d4838e6a6354cd34a1f65fdf2116a42b74',
          stakingContractPackage: '2eb2755831e3fb07b2792391f953a8a7852cb635524e670f044825ba9cf28b84'
        },
        lpAddress: '660ab26188f706515801c96d912c6f40d397cae335ca218b5557cc8120d6ab06',
        lpContractPackage: 'ce30a8250027d274c00c34927568a087e82291f80e8c3740b0e37f5c128bf9d7',
        projectId: '7ca193f0eff28082b3f3df1cbb359b76',
        stakeAPIURL: 'https://shiboo-deploy-backend.onrender.com/testnet',
      }
    default:
      return {
        network: 'casper',
        chainId: 131614895977472,
        explorerURL: 'https://cspr.live',
        nodeURL: "https://rpc.shiboo.io/http://51.222.244.145:7777/rpc",
        makeAPI: 'https://event-store-api-clarity-mainnet.make.services',
        validator: '01c00bd605e492d7e36140136ac71111eb00b2d010be22d84a02ac0971776583fb',
        minStake: 0,
        contracts: {
          stakingContract: '0bf3bbfe9aa9952626cdce63b8cc0e0e8f5bb2d5602e0a2827b167a24cad05be',
          stakingContractPackage: '225cad3e091bb1750d36686e0290f9bcbc4bac608849f19aa00f101b4f895f09'
        },
        lpAddress: 'b0a767e2d62b07e49ccefa7908d4c2d259cb740180b09156ffb28f86a61ea190',
        lpContractPackage: 'b86a24a878c3f3d6c39a327d1c311f7cee4c65800561c805c4332a436d701849',
        projectId: '7ca193f0eff28082b3f3df1cbb359b76',
        stakeAPIURL: 'https://shiboo-deploy-backend.onrender.com/livenet',
        apiURL: ''
      }
    // default:
    // https://rpc.testnet.casperlabs.io
    //   return {
    //     network: 'casper',
    //     chainId: 131614895977472,
    //     explorerURL: 'https://cspr.live',
    //     nodeURL: "https://casper-node.tor.us",
    //     makeAPI: 'https://event-store-api-clarity-mainnet.make.services',
    //     validator: '01c00bd605e492d7e36140136ac71111eb00b2d010be22d84a02ac0971776583fb',
    //     minStake: 0,
    //     contracts: {
    //       stakingContract: '0bf3bbfe9aa9952626cdce63b8cc0e0e8f5bb2d5602e0a2827b167a24cad05be',
    //       stakingContractPackage: '225cad3e091bb1750d36686e0290f9bcbc4bac608849f19aa00f101b4f895f09'
    //     },
    //     lpAddress: 'b0a767e2d62b07e49ccefa7908d4c2d259cb740180b09156ffb28f86a61ea190',
    //     lpContractPackage: 'b86a24a878c3f3d6c39a327d1c311f7cee4c65800561c805c4332a436d701849',
    //     projectId: '7ca193f0eff28082b3f3df1cbb359b76',
    //     stakeAPIURL: 'http://localhost:10000/livenet',
    //     apiURL: ''
    //   }
    // return {
    //   network: "casper",
    //   chainId: 131614895977472,
    //   explorerURL: "https://cspr.live",
    //   nodeURL: "https://casper-node.tor.us",
    //   makeAPI: "https://event-store-api-clarity-mainnet.make.services",
    //   validator:
    //     "01c00bd605e492d7e36140136ac71111eb00b2d010be22d84a02ac0971776583fb",
    //   minStake: 0,
    //   contracts: {
    //     stakingContract: '109e6e56f9541362768f1162280f5eade23c8ce3b993f35c30b12c458d477647',
    //     stakingContractPackage: 'db934fd08b146d13e089fad281ec9db5663ae8415e806b5670e51ecb118dd05a'
    //   },
    //   lpAddress: 'b0a767e2d62b07e49ccefa7908d4c2d259cb740180b09156ffb28f86a61ea190',
    //   lpContractPackage: 'b86a24a878c3f3d6c39a327d1c311f7cee4c65800561c805c4332a436d701849',
    //   projectId: "7ca193f0eff28082b3f3df1cbb359b76",
    //   stakeAPIURL: 'https://shiboo-deploy-backend.onrender.com/livenet',
    // apiURL: '',
    // };
  }
}
