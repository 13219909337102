import styled from 'styled-components/macro'
import Modal from 'components/Modal'

const InfoWrapper = styled.div`
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  padding: 2rem;
`

const Text = styled.p`
    font-family: Roboto Mono;
    font-weight: 300;
    font-size: 1rem;
    color: #051014;
    margin-bottom: 2rem;
    text-align: center;
`

const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #565660;
  font-weight: 500;
  font-family: Montserrat;

  &:hover {
    text-decoration: underline;
    color: #181920;
  }
  &:focus {
    outline: none;
    text-decoration: underline;
  }
  &:active {
    text-decoration: none;
  }
`

export const ConfirmButton = styled.button`
  width: 100%;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 1rem;
  border: 1px solid #00DC70;
  background: #00DC70;
  font-family: Roboto Mono;
  font-weight: 300;
  font-size: 1rem;
  color: #051014;
`

export const BackButton = styled.button`
  width: 100%;
  padding: 1rem;
  margin: 0.5rem 0;
  border-radius: 1rem;
  border: 1px solid #00DC70;
  background: #fff;
  font-family: Roboto Mono;
  font-weight: 300;
  font-size: 1rem;
  color: #051014;
`

interface ICompoundModalProps {
  show: boolean
  onHide: () => void
  onConfirm?: () => void
}

function CompoundModal(props: ICompoundModalProps): JSX.Element {
  const { show, onHide, onConfirm } = props

  return (
    <Modal show={show} title="Confirm Your Compound" onHide={onHide}>
        <Text>
          This action will claim your rewards and automatically restake them.
        </Text>
        <Text>
          A 50 CSPR gas fee is required for this operation.
        </Text>
        <ConfirmButton onClick={onConfirm}>
            CONFIRM AND COMPOUND
        </ConfirmButton>
        <BackButton onClick={onHide}>
            BACK
        </BackButton>
    </Modal>
  )
}

export default CompoundModal
