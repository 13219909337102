import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { CiWallet } from "react-icons/ci";

export const HeaderMain = styled.header`
  position: relative;
  // background: linear-gradient(180deg, #051014 0%, rgba(5, 16, 20, 0) 100%);
  background-color: #051014;
  top: 0;
  width: 100%;
  z-index: 1;
`;
export const Column = styled.div`
    width: 95%;
    margin: 0 auto;
`
export const HeaderWrapper = styled.div`
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;

  @media (min-width: 992px) {
    display: flex;
  }
`;

export const HeaderMobile = styled.div`
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  @media (min-width: 992px) {
    display: none;
  }
`;

export const MobileLogo = styled.img`
  max-width: 100%;
`;

export const TitleWapper = styled.div`
  position: relative;
  background: transparent;
  display: flex;
  align-items: center;
  height: 100%;

  span {
    font-weight: 700;
    font-size: 23px;
    line-height: 28px;
    color: #000;
    text-transform: uppercase;
  }
`;

export const NavBarWrap = styled.div`
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    justify-content: left;
    width: fit-content;
    margin-bottom: 0;

    li {
      padding: 4px 24px;
      border-right: 1px solid #ffffff;

      &:last-child {
        border-right: none;
      }
    }
  }
`;

export const MenuTab = styled.select`
  background: transparent;
  border: none;
`;

export const NavLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  text-decoration: none;

  &:hover {
    color: #000000;
    transition: all 0.5s ease;
    cursor: pointer;
  }
`;

export const NavLinkA = styled.a`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  text-decoration: none;

  &:hover {
    color: #161f2f;
    transition: all 0.5s ease;
    cursor: pointer;
  }
`;

export const NavLink2 = styled.a`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  text-decoration: none;

  &:hover {
    color: #161f2f;
    transition: all 0.5s ease;
    cursor: pointer;
  }
`;

export const MenuToggle = styled.button`
  background: transparent;
  border: none;

  @media (min-width: 992px) {
    display: none;
  }
`;

export const ButtonWrapper = styled.div`
  display: block;
`;

export const StyledWalletIcon = styled(CiWallet)`
  @media (min-width: 992px) {
    display: none;
  }
`;

export const StakedLink = styled.a`
    font-family: Roboto Mono;
    font-size: 18px;
    font-weight: 300;
    line-height: 18.46px;
    text-align: right;
    color: #fff;
    margin-right: 2rem;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: #00DC70;
    }
`;