import { useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { useConnectWalletCallback } from 'state/wallet/hooks'
import styled, { keyframes } from 'styled-components/macro'
import { Wallet } from './ConnectModal'

const StyledButton = styled.button`
  margin-bottom: 1rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: #000;
  border-radius: 15px;
  border: 0.5px solid #000;
  padding: 1.5rem 2rem;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  color: ${({ disabled }) => (disabled ? '#999' : '#000')};
  text-align: left;
  font-family: Roboto Mono;

  &:last-child {
    margin: 0;
  }

  img {
    width: 35px;
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  }

  &:hover {
    background: ${({ disabled }) => (disabled ? '#f7f7f7' : '#00dd72')};
    border-color: #00dd72;
  }
`
const CommingSoon = styled.span`
  display: block;
  font-size: 12px;
`
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const Spinner = styled(CgSpinner)`
  animation: ${spin} 0.75s linear infinite;
  margin-right: 0.5rem;
`

function WalletButton({ wallet, onDismiss }: { wallet: Wallet; onDismiss: () => void }): JSX.Element {
  const connectWalletCallback = useConnectWalletCallback()
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    setLoading(true)
    onDismiss()
    await connectWalletCallback(wallet.connector)
  }

  return (
    <StyledButton disabled={wallet.disable} onClick={onClick}>
      <span>
        {loading && <Spinner />}
        {wallet.name}
        {wallet.disable && <CommingSoon>Comming Soon</CommingSoon>}
      </span>
      <img src={wallet.image} />
    </StyledButton>
  )
}

export default WalletButton
