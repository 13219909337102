import { useState, useEffect, useCallback } from "react";
import {
  Column,
  SectionWrapper,
  ContentWrapper,
  Title,
  Balance,
  LabelText,
  InputStyled,
  ButtonStyled,
  FormSection,
  MaxButton,
  InputDiv,
  StatisticTitle,
  StatisticContent,
  LinkSection,
  Link,
  SocialBox,
  ColumnResponsive,
  RewardBox,
  RewardItem
} from "./Styled";
import { useAccount, useWalletProvider, useConnectorId, } from "state/wallet/hooks";
import { useActiveWeb3React } from "hooks";
import { useBalance } from "hooks/useBlance";
import { CasperClient, CLByteArray, CLPublicKey, CLValueBuilder, DeployUtil, RuntimeArgs, decodeBase16 } from "casper-js-sdk";
import BigNumber from "bignumber.js";
import getConfig from "config/config";
import ConnectModal from "components/ConnectButton/ConnectModal";
import { getDeployFunction, sleep, toFixedCustom, waitForDeployExecution } from "utils";
import { useTransactionAdder } from "state/transactions/hooks";
import { Buffer } from "buffer";
import { debounce } from "lodash";
import PulseLoader from 'react-spinners/PulseLoader'
import { ToastDimiss, ToastError } from "components/Toast";
import formatNumber from "utils";
import axios from "axios";

import Shiboo from 'assets/images/Shiboo.svg'
import Casper from 'assets/images/casper-logo.svg'
import { toast } from "react-toastify";
import PenaltyModal from "./penaltyModal";
import LogoFooter from 'assets/images/Footer-logo.svg'
import CasperFooter from 'assets/images/casper-footer.svg'
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { parseUnits } from "ethers/lib/utils";
import CompoundModal from "./compoundModal";


function StakePage(): JSX.Element {
  const account = useAccount();
  const provider = useWalletProvider();
  const connectorId = useConnectorId();
  const { connector } = useActiveWeb3React();
  const addTransaction = useTransactionAdder();

  
  const [amountStakeLP, setAmountStakeLP] = useState<string | number>();
  const [amountStakeCSPR, setAmountStakeCSPR] = useState<string | number>();
  const [amountUnstake, setAmountUnstake] = useState<string | number>();
  
  const [balance, setBalance] = useState<number | string>();
  const [csprBalance, setCSPRBalance] = useState<number | string>(0);
  
  const [showConnectModal, setShowConnectModal] = useState(false);

  const [disabledButtonStakeLP, setDisabledButtonStakeLP] = useState<boolean>(true);
  const [disabledButtonStakeCSPR, setDisabledButtonStakeCSPR] = useState<boolean>(true);
  const [disabledButtonUnstake, setDisabledButtonUnstake] = useState<boolean>(true);

  const [textButtonStakeLP, setTextButtonStakeLP] = useState<string>("ENTER AMOUNT");
  const [textButtonStakeCSPR, setTextButtonStakeCSPR] = useState<string>("ENTER AMOUNT");
  const [textButtonUnstake, setTextButtonUnstake] = useState<string>("ENTER AMOUNT");

  const [totalStake, setTotalStake] = useState<number>(0);
  const [totalUSD, setTotalUSD] = useState<number>(0);
  const [marketCap, setMarketCap] = useState<any>(0);
  const [apr, setApr] = useState<any>(0);
  const [tab, setTab] = useState(1)
  const [loadingBalance, setLoadingBalance] = useState<boolean>(false)
  const [csprReward, setCSPRReward] = useState<string | number | undefined>(0)
  const [shibooReward, setShibooReward] = useState<string | number | undefined>(0)
  const [staked, setStaked] = useState<string | number | undefined>(0)
  const [stakedUSD, setStakedUSD] = useState<string | number | undefined>(0)
  const [stakedShiboo, setStakedShiboo] = useState<string | number | undefined>(0)
  const [stakedCSPR, setStakedCSPR] = useState<string | number | undefined>(0)
  const [loadingStakeInfo, setLoadingStakeInfo] = useState<boolean>(false)
  const [penaltyRate, setPenaltyRate] = useState<string | number | undefined>(0)
  const [showPenaltyModal, setShowPenaltyModal] = useState<boolean>(false)
  const [showCompoundModal, setShowCompoundModal] = useState<boolean>(false)

  const fetchApr = async () => {
    const responseAPR = await axios.get(
      `${getConfig().stakeAPIURL}/staking/apr`
    );
    if (responseAPR.data) {
      setApr(responseAPR.data.apr.toFixed(0));
      setMarketCap(responseAPR.data.marketCap.toFixed(0));
      setTotalStake(responseAPR.data.totalStaked);
      setTotalUSD(responseAPR.data.usdValue);
    }
  };

  useEffect(() => {
    fetchApr()
  }, []);

  const balanceCallback = useBalance(account!, getConfig()?.lpAddress!);

  const loadTokenBalance = async () => {
    const _tokenBalance = await balanceCallback();
    const _balance = _tokenBalance ? new BigNumber(_tokenBalance).dividedBy(10 ** 18).toString() : 0;
    setBalance(_balance);
  };

  const loadCSPRBalance = async () => {
    if (account) {
      const casperClient = new CasperClient(getConfig().nodeURL);
      const publicKey = CLPublicKey.fromHex(account);
      const csprBalance = await casperClient.balanceOfByPublicKey(publicKey);
      setCSPRBalance(new BigNumber(csprBalance.toString()).dividedBy(10 ** 9).toString());
    }
  };

  useEffect(() => {
    setLoadingBalance(true)
    loadTokenBalance();
    loadCSPRBalance();
    setLoadingBalance(false)

  }, [account]);

  const getStakeInfo = async () => {
    try {
      if (account) {
        setLoadingStakeInfo(true)
        const accountHashStr = CLPublicKey.fromHex(account).toAccountHashStr()
        const accountHash = accountHashStr.split('-')[2]
        const stakeAPIURL = getConfig().stakeAPIURL
        const res = await axios.get(`${stakeAPIURL}/staking/userData/${accountHash}`)
        if (res && res.data && res.data.userInfo) {
          // console.log('stake info', res.data.userInfo)
          const stakeInfo = res.data.userInfo
          const _totalDeposit = new BigNumber(stakeInfo?.totalDeposit ?? 0).dividedBy(10 ** 18).toString()
          const _csprReward = new BigNumber(stakeInfo?.csprReward ?? 0).dividedBy(10 ** 9).toString()
          const _shibooReward = new BigNumber(stakeInfo?.shibooReward ?? 0).dividedBy(10 ** 18).toString()

          setStaked(_totalDeposit)
          setStakedUSD(stakeInfo?.totalDepositUSD)
          setCSPRReward(_csprReward)
          setShibooReward(_shibooReward)
          setStakedCSPR(stakeInfo?.totalDepositCSPR)
          setStakedShiboo(stakeInfo?.totalDepositShiboo)
        }
        setLoadingStakeInfo(false)
      }
    } catch (error) {
      setLoadingStakeInfo(false)
      setStaked(0)
      setStakedUSD(0)
      setCSPRReward(0)
      setShibooReward(0)
      setStakedCSPR(0)
      setStakedShiboo(0)
      console.log('error: ', error)
    }
  }

  useEffect(() => {
    getStakeInfo()
  }, [account])

  useEffect(() => {
    const handleStatusChangeLP = async () => {
      const _amount = Number(amountStakeLP);
      const _balance = Number(balance);
      const minStake = getConfig().minStake
      if (_amount < minStake) {
        setTextButtonStakeLP("Requires 0 minimum");
        setDisabledButtonStakeLP(true);
      }
      if (_amount && _amount === 0) {
        setTextButtonStakeLP("ENTER AMOUNT");
        setDisabledButtonStakeLP(true);
      }
      if (_amount > _balance) {
        setTextButtonStakeLP("INSUFFICIENT BALANCE");
        setDisabledButtonStakeLP(true);
      }
      if (_amount >= minStake && _amount <= _balance) {
        setTextButtonStakeLP("STAKE LP");
        setDisabledButtonStakeLP(false);
      }
    };
    const debouncedHandleStatusChangeLP = debounce(handleStatusChangeLP, 300);
    debouncedHandleStatusChangeLP();
    return () => {
      debouncedHandleStatusChangeLP.cancel();
    };
  }, [amountStakeLP, balance]);

  useEffect(() => {
    const handleStatusChangeCSPR = async () => {
      const _amount = Number(amountStakeCSPR);
      const _balance = Number(csprBalance);
      const minStake = getConfig().minStake
      if (_amount < minStake) {
        setTextButtonStakeCSPR("Requires 0 minimum");
        setDisabledButtonStakeCSPR(true);
      }
      if (_amount && _amount === 0) {
        setTextButtonStakeCSPR("ENTER AMOUNT");
        setDisabledButtonStakeCSPR(true);
      }
      if (_amount > _balance) {
        setTextButtonStakeCSPR("INSUFFICIENT BALANCE");
        setDisabledButtonStakeCSPR(true);
      }
      if (_amount >= minStake && _amount <= _balance) {
        setTextButtonStakeCSPR("CONVERT CSPR AND STAKE");
        setDisabledButtonStakeCSPR(false);
      }
    };
    const debouncedHandleStatusChangeCSPR = debounce(handleStatusChangeCSPR, 300);
    debouncedHandleStatusChangeCSPR();
    return () => {
      debouncedHandleStatusChangeCSPR.cancel();
    };
  }, [amountStakeCSPR, csprBalance]);

  useEffect(() => {
    const handleStatusChangeUnstake = async () => {
      const _amount = Number(amountUnstake);
      const _balance = Number(staked);
      const minStake = getConfig().minStake
      if (_amount < minStake) {
        setTextButtonUnstake("Requires 0 minimum");
        setDisabledButtonUnstake(true);
      }
      if (_amount && _amount === 0) {
        setTextButtonUnstake("ENTER AMOUNT");
        setDisabledButtonUnstake(true);
      }
      if (_amount > _balance) {
        setTextButtonUnstake("INSUFFICIENT BALANCE");
        setDisabledButtonUnstake(true);
      }
      if (_amount >= minStake && _amount <= _balance) {
        setTextButtonUnstake("UNSTAKE LP");
        setDisabledButtonUnstake(false);
      }
    };
    const debouncedHandleStatusChangeUnstake = debounce(handleStatusChangeUnstake, 300);
    debouncedHandleStatusChangeUnstake();
    return () => {
      debouncedHandleStatusChangeUnstake.cancel();
    };
  }, [amountUnstake, staked]);

  const onStake = async () => {
    const gasFee = 10000000000;
    try {
      if (account) {
        const senderKey = CLPublicKey.fromHex(account);
        const deployParams = new DeployUtil.DeployParams(
          senderKey,
          getConfig().network ?? "casper-test",
          1,
          1800000
        );
       
        const _amount = new BigNumber(amountStakeLP!).multipliedBy(10 ** 18).toString();

        // console.log("amount: ", _amount);

        const runtimeArgs = RuntimeArgs.fromMap({
          entry_name: CLValueBuilder.string(tab == 1 ? 'deposit' : 'withdraw'),
          pool_id: CLValueBuilder.u64(1),
          amount: CLValueBuilder.u256(_amount),
          staking_package: CLValueBuilder.key(
            new CLByteArray(decodeBase16(getConfig()?.contracts?.stakingContractPackage!)),
          ),
          staked_token: CLValueBuilder.key(
            new CLByteArray(decodeBase16(getConfig()?.lpContractPackage!)),
          ),
        })

        const response = await axios.get('/session-stake-unstake.wasm', {
          responseType: 'arraybuffer',
        })

        const instance = new Uint8Array(Buffer.from(response.data, 'binary'))

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newModuleBytes(instance, runtimeArgs),
          DeployUtil.standardPayment(gasFee)
        );

        const deployJson = DeployUtil.deployToJson(deploy);

        const casperClient = new CasperClient(getConfig().nodeURL);
        if (deploy && provider) {

          const hash = await getDeployFunction(
            account,
            casperClient,
            connectorId,
            deploy,
            provider,
            deployJson,
            connector
          );
          if (hash) {
            // console.log("hash: ", hash);
            toast.loading(
              <div style={{ cursor: 'pointer' }} onClick={() => window.open(getConfig().explorerURL + `/deploy/${hash}`, '_blank')}>
                Wait for deploying transaction
              </div>,
              {
                position: 'bottom-right',
                autoClose: 60000
              }
            )

            addTransaction(hash, {
              summary: `${tab == 1 ? 'Stake' : 'Unstake'} ${amountUnstake}`,
            });
            try {
              const [_deploy, _raw] = await waitForDeployExecution(casperClient, hash)
              if (_deploy) {
                ToastDimiss()
                toast.success('Deploy success. Check your transaction in wallet button', { position: 'bottom-right' })
              }
              await loadTokenBalance()
              await sleep(3000)
              await getStakeInfo()
            } catch (error) {
              ToastDimiss()
              ToastError(error)
            }

            setAmountUnstake(0);
            setAmountStakeCSPR(0);
            setAmountStakeLP(0);
          }

        }
      }
    } catch (error) {
      ToastDimiss()
      ToastError(error)
      setAmountUnstake(0);
      setAmountStakeCSPR(0);
      setAmountStakeLP(0);
      console.log("error: ", error);
    }
  };

  const handleCheckBeforeUnstake = async () => {
    try {
      if (account) {
        const accountHashStr = CLPublicKey.fromHex(account).toAccountHashStr()
        const accountHash = accountHashStr.split('-')[2]
        const stakeAPIURL = getConfig().stakeAPIURL
        const res = await axios.get(`${stakeAPIURL}/staking/userPenaltyRate/${accountHash}`)
        if (res && res.data) {
          const _penaltyRate = res.data.penaltyRate.toFixed(2)
          setPenaltyRate(_penaltyRate)
          //console.log('penaltyRate', _penaltyRate)
          if (Number(_penaltyRate) > 0) {
            setShowPenaltyModal(true)
          } else {
            await onUnstake()
          }

        }
      }

    } catch (error) {
      console.log('error', error)
    }
  }

  const onUnstake = async () => {
    const gasFee = 10000000000;
    try {
      if (account) {
        const senderKey = CLPublicKey.fromHex(account);
        const deployParams = new DeployUtil.DeployParams(
          senderKey,
          getConfig().network ?? "casper-test",
          1,
          1800000
        );
        const _amount = new BigNumber(amountUnstake!).multipliedBy(10 ** 18).toString();

        // console.log("amount: ", _amount);

        const runtimeArgs = RuntimeArgs.fromMap({
          entry_name: CLValueBuilder.string('withdraw'),
          pool_id: CLValueBuilder.u64(1),
          amount: CLValueBuilder.u256(_amount),
          staking_package: CLValueBuilder.key(
            new CLByteArray(decodeBase16(getConfig()?.contracts?.stakingContractPackage!)),
          ),
          staked_token: CLValueBuilder.key(
            new CLByteArray(decodeBase16(getConfig()?.lpContractPackage!)),
          ),
        })

        const response = await axios.get('/session-stake-unstake.wasm', {
          responseType: 'arraybuffer',
        })

        const instance = new Uint8Array(Buffer.from(response.data, 'binary'))

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newModuleBytes(instance, runtimeArgs),
          DeployUtil.standardPayment(gasFee)
        );

        const deployJson = DeployUtil.deployToJson(deploy);

        const casperClient = new CasperClient(getConfig().nodeURL);
        if (deploy && provider) {

          const hash = await getDeployFunction(
            account,
            casperClient,
            connectorId,
            deploy,
            provider,
            deployJson,
            connector
          );
          if (hash) {
            // console.log("hash: ", hash);
            setShowPenaltyModal(false)
            toast.loading(
              <div style={{ cursor: 'pointer' }} onClick={() => window.open(getConfig().explorerURL + `/deploy/${hash}`, '_blank')}>
                Wait for deploying transaction
              </div>,
              {
                position: 'bottom-right',
                autoClose: 60000
              }
            )

            addTransaction(hash, {
              summary: `Unstake ${amountUnstake}`,
            });
            try {
              const [_deploy, _raw] = await waitForDeployExecution(casperClient, hash)
              if (_deploy) {
                ToastDimiss()
                toast.success('Deploy success. Check your transaction in wallet button', { position: 'bottom-right' })
              }
              await loadTokenBalance()
              await sleep(3000)
              await getStakeInfo()
            } catch (error) {
              ToastDimiss()
              ToastError(error)
            }

            setAmountUnstake(0);
            setAmountStakeCSPR(0);
            setAmountStakeLP(0);
          }

        }
      }
    } catch (error) {
      ToastDimiss()
      ToastError(error)
      setAmountUnstake(0);
      setAmountStakeCSPR(0);
      setAmountStakeLP(0);
      console.log("error: ", error);
    }
  };

  const onClaimCSPR = async () => {
    const gasFee = 10000000000;
    try {
      if (account && csprReward) {
        const senderKey = CLPublicKey.fromHex(account);
        const deployParams = new DeployUtil.DeployParams(
          senderKey,
          getConfig().network ?? "casper-test",
          1,
          1800000
        );
        const contractHashAsByteArray = decodeBase16(getConfig().contracts.stakingContract)
        const _amount = parseUnits(csprReward?.toString(), 9)

        const runtimeArgs = RuntimeArgs.fromMap({
          amount: CLValueBuilder.u512(_amount),
        })

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            contractHashAsByteArray,
            'claim_cspr_reward',
            runtimeArgs,
          ),
          DeployUtil.standardPayment(gasFee)
        );

        const deployJson = DeployUtil.deployToJson(deploy);

        const casperClient = new CasperClient(getConfig().nodeURL);
        if (deploy && provider) {
          const hash = await getDeployFunction(
            account,
            casperClient,
            connectorId,
            deploy,
            provider,
            deployJson,
            connector
          );
          if (hash) {
            // console.log("hash: ", hash);
            toast.loading(
              <div style={{ cursor: 'pointer' }} onClick={() => window.open(getConfig().explorerURL + `/deploy/${hash}`, '_blank')}>
                Wait for deploying transaction
              </div>,
              {
                position: 'bottom-right',
                autoClose: 60000
              }
            )

            addTransaction(hash, {
              summary: `Claimed ${csprReward} CSPR`,
            });
            try {
              const [_deploy, _raw] = await waitForDeployExecution(casperClient, hash)
              if (_deploy) {
                ToastDimiss()
                toast.success('Deploy success. Check your transaction in wallet button', { position: 'bottom-right' })
              }
              //await loadTokenBalance()
              await sleep(3000)
              await getStakeInfo()
            } catch (error) {
              ToastDimiss()
              ToastError(error)
            }
          }
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  const handleConfirmCompound = async () => {
    try {
      if (account) {
        setShowCompoundModal(true)
      }

    } catch (error) {
      console.log('error', error)
    }
  }

  const onCompound = async () => {
    const gasFee = 50000000000;
    try {
      setShowCompoundModal(false)
      if (account) {
        const senderKey = CLPublicKey.fromHex(account);
        const deployParams = new DeployUtil.DeployParams(
          senderKey,
          getConfig().network ?? "casper-test",
          1,
          1800000
        );

        //get deadline 
        const deadline = new Date().getTime() + 1800000;

        const runtimeArgs = RuntimeArgs.fromMap({
          deadline: CLValueBuilder.u64(deadline),
          pool_id: CLValueBuilder.u64(1),
          amount: CLValueBuilder.u512("1000000000000000")
        });


        const response = await axios.get('/compound.wasm', {
          responseType: 'arraybuffer',
        });

        const instance = new Uint8Array(Buffer.from(response.data, 'binary'));

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newModuleBytes(instance, runtimeArgs),
          DeployUtil.standardPayment(gasFee)
        );

        const deployJson = DeployUtil.deployToJson(deploy);
        console.log('deployJson', deployJson)
        const casperClient = new CasperClient(getConfig().nodeURL);
        if (deploy && provider) {

          const hash = await getDeployFunction(
            account,
            casperClient,
            connectorId,
            deploy,
            provider,
            deployJson,
            connector
          );
          if (hash) {
            toast.loading(
              <div style={{ cursor: 'pointer' }} onClick={() => window.open(getConfig().explorerURL + `/deploy/${hash}`, '_blank')}>
                Wait for deploying transaction
              </div>,
              {
                position: 'bottom-right',
                autoClose: 60000
              }
            )

            addTransaction(hash, {
              summary: `Compound ${csprReward}`,
            });
            try {
              const [_deploy, _raw] = await waitForDeployExecution(casperClient, hash)
              if (_deploy) {
                ToastDimiss()
                toast.success('Deploy success. Check your transaction in wallet button', { position: 'bottom-right' })
              }
              await loadTokenBalance()
              await sleep(3000)
              await getStakeInfo()
            } catch (error) {
              ToastDimiss()
              ToastError(error)
            }
          }

        }
      }
    } catch (error) {
      ToastDimiss()
      ToastError(error)
      console.log("error: ", error);
    }
  };

  const onStakeCSPR = async () => {
    const gasFee = 45000000000;
    try {
      if (account) {
        const senderKey = CLPublicKey.fromHex(account);
        const deployParams = new DeployUtil.DeployParams(
          senderKey,
          getConfig().network ?? "casper-test",
          1,
          1800000
        );

        const stake_amount = new BigNumber(amountStakeCSPR!).multipliedBy(10 ** 9).toString();
        console.log("stake_amount: ", stake_amount);

        //get deadline 
        const deadline = new Date().getTime() + 1800000;

        const runtimeArgs = RuntimeArgs.fromMap({
          deadline: CLValueBuilder.u64(deadline),
          pool_id: CLValueBuilder.u64(1),
          amount: CLValueBuilder.u512(stake_amount)
        });


        const response = await axios.get('/stake_cspr.wasm', {
          responseType: 'arraybuffer',
        });

        const instance = new Uint8Array(Buffer.from(response.data, 'binary'));

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newModuleBytes(instance, runtimeArgs),
          DeployUtil.standardPayment(gasFee)
        );

        const deployJson = DeployUtil.deployToJson(deploy);
        console.log('deployJson', deployJson)
        const casperClient = new CasperClient(getConfig().nodeURL);
        if (deploy && provider) {

          const hash = await getDeployFunction(
            account,
            casperClient,
            connectorId,
            deploy,
            provider,
            deployJson,
            connector
          );
          if (hash) {
            toast.loading(
              <div style={{ cursor: 'pointer' }} onClick={() => window.open(getConfig().explorerURL + `/deploy/${hash}`, '_blank')}>
                Wait for deploying transaction
              </div>,
              {
                position: 'bottom-right',
                autoClose: 60000
              }
            )

            addTransaction(hash, {
              summary: `Stake CSPR ${amountStakeCSPR}`,
            });
            try {
              const [_deploy, _raw] = await waitForDeployExecution(casperClient, hash)
              if (_deploy) {
                ToastDimiss()
                toast.success('Deploy success. Check your transaction in wallet button', { position: 'bottom-right' })
              }
              await loadTokenBalance()
              await sleep(3000)
              await getStakeInfo()
            } catch (error) {
              ToastDimiss()
              ToastError(error)
            }

            setAmountUnstake(0);
            setAmountStakeCSPR(0);
            setAmountStakeLP(0);
          }

        }
      }
    } catch (error) {
      ToastDimiss()
      ToastError(error)
      setAmountUnstake(0);
      setAmountStakeCSPR(0);
      setAmountStakeLP(0);
      console.log("error: ", error);
    }
  };

  const onClaimShiboo = async () => {
    const gasFee = 10000000000;
    try {
      if (account) {
        const senderKey = CLPublicKey.fromHex(account);
        const deployParams = new DeployUtil.DeployParams(
          senderKey,
          getConfig().network ?? "casper-test",
          1,
          1800000
        );
        const contractHashAsByteArray = decodeBase16(getConfig().contracts.stakingContract)

        const runtimeArgs = RuntimeArgs.fromMap({})

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            contractHashAsByteArray,
            'claim_shiboo_reward',
            runtimeArgs,
          ),
          DeployUtil.standardPayment(gasFee)
        );

        const deployJson = DeployUtil.deployToJson(deploy);

        const casperClient = new CasperClient(getConfig().nodeURL);
        if (deploy && provider) {
          const hash = await getDeployFunction(
            account,
            casperClient,
            connectorId,
            deploy,
            provider,
            deployJson,
            connector
          );
          if (hash) {
            // console.log("hash: ", hash);
            toast.loading(
              <div style={{ cursor: 'pointer' }} onClick={() => window.open(getConfig().explorerURL + `/deploy/${hash}`, '_blank')}>
                Wait for deploying transaction
              </div>,
              {
                position: 'bottom-right',
                autoClose: 60000
              }
            )

            addTransaction(hash, {
              summary: `Claimed ${shibooReward} SHIBOO`,
            });
            try {
              const [_deploy, _raw] = await waitForDeployExecution(casperClient, hash)
              if (_deploy) {
                ToastDimiss()
                toast.success('Deploy success. Check your transaction in wallet button', { position: 'bottom-right' })
              }
              //await loadTokenBalance()
              await sleep(3000)
              await getStakeInfo()
            } catch (error) {
              ToastDimiss()
              ToastError(error)
            }
          }
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  return (
    <Column>
      <div>
        <Title style={{ marginLeft: '1rem' }}>
          STAKING INFO
        </Title>
        <ContentWrapper className="mt-3 mb-3">
          <div
            style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '2rem' }}
          >
            <div className="d-flex flex-col align-items-center gap-2">
              <StatisticContent>${formatNumber(marketCap)}</StatisticContent>
              <StatisticTitle>Shiboo Market cap</StatisticTitle>
            </div>
            <div className="d-flex flex-col align-items-center gap-2">
              <StatisticContent>{apr}%</StatisticContent>
              <StatisticTitle>APR</StatisticTitle>
            </div>
            <div className="d-flex flex-col align-items-center gap-2">
              <StatisticContent>
                {formatNumber(totalStake)} LP
              </StatisticContent>
              <StatisticTitle>Total staked</StatisticTitle>
            </div>
            <div className="d-flex flex-col align-items-center gap-2">
              <StatisticContent>
                {formatNumber(totalUSD)} $
              </StatisticContent>
              <StatisticTitle>USD value</StatisticTitle>
            </div>
          </div>
        </ContentWrapper>
      </div>

      <SectionWrapper className="row justify-content-center">
        <div className="col-lg-4 col-md-12 mt-2">
          <div className="d-flex gap-2 align-items-center" style={{ marginLeft: '1rem' }}>
            <Title
              style={{
                color: tab == 1 ? '#fff' : 'rgba(255, 255, 255, 0.56)',
                fontSize: '24px',
                cursor: 'pointer',
                paddingRight: '0.5rem',
                borderRight: '1px solid rgba(255, 255, 255, 0.56)',
              }}
              onClick={() => setTab(1)}
            >
              STAKE
            </Title>
            <Title
              style={{
                color: tab == 2 ? '#fff' : 'rgba(255, 255, 255, 0.56)',
                fontSize: '24px',
                cursor: 'pointer',
                paddingRight: '0.5rem',
              }}
              onClick={() => setTab(2)}
            >
              UNSTAKE
            </Title>
          </div>
          <ContentWrapper>
            <ConnectModal
              show={showConnectModal}
              onHide={() => setShowConnectModal(false)}
            />
            {tab == 1 ? (<>
              <FormSection>
                <div>
                  <div className="d-flex justify-content-between my-2">
                    <LabelText>Amount</LabelText>
                    <LabelText style={{ color: '#00DC70' }}>Balance:&nbsp;
                      {account
                        ? (
                          loadingBalance ? (
                            <span>
                              <PulseLoader size={5} color="#00DC70" />
                            </span>
                          ) : (
                            toFixedCustom(balance, 2)
                          )
                        )
                        : 0} LP
                    </LabelText>
                  </div>
                  <InputDiv>
                    <InputStyled
                      value={amountStakeLP}
                      placeholder="0"
                      type="number"
                      onChange={(e) =>
                        setAmountStakeLP(Number(e.target.value) < 0 ? "0" : e.target.value)
                      }
                    />

                    <MaxButton onClick={() => setAmountStakeLP(balance)}>
                      Max
                    </MaxButton>

                  </InputDiv>
                </div>
              </FormSection>
              {account &&
                <ButtonStyled onClick={onStake} disabled={disabledButtonStakeLP}>
                  {textButtonStakeLP}
                </ButtonStyled>
                
              }
              <hr />
              <FormSection>
                <div>
                  <div className="d-flex justify-content-between my-2">
                    <LabelText>Amount</LabelText>
                    <LabelText style={{ color: '#00DC70' }}>Balance:&nbsp;
                      {account
                        ? (
                          loadingStakeInfo ? (
                            <span>
                              <PulseLoader size={5} color="#00DC70" />
                            </span>
                          ) : (
                            toFixedCustom(csprBalance, 2)
                          )
                        )
                        : 0} CSPR
                    </LabelText>
                  </div>
                  <InputDiv>
                    <InputStyled
                      value={amountStakeCSPR}
                      placeholder="0"
                      type="number"
                      onChange={(e) =>
                        setAmountStakeCSPR(Number(e.target.value) < 0 ? "0" : e.target.value)
                      }
                    />

                    <MaxButton onClick={() => setAmountStakeCSPR(csprBalance)}>
                      Max
                    </MaxButton>

                  </InputDiv>
                </div>
              </FormSection>
              {account ?
                <ButtonStyled onClick={onStakeCSPR} disabled={disabledButtonStakeCSPR}>
                  {textButtonStakeCSPR}
                </ButtonStyled> :
                <ButtonStyled onClick={() => setShowConnectModal(true)}> CONNECT WALLET </ButtonStyled>
              }
            </>) : (<>
              <FormSection>
                <div>
                  <div className="d-flex justify-content-between my-2">
                    <LabelText>Amount</LabelText>
                    <LabelText style={{ color: '#00DC70' }}>Balance:&nbsp;
                      {account
                        ? (
                          loadingBalance ? (
                            <span>
                              <PulseLoader size={5} color="#00DC70" />
                            </span>
                          ) : (
                            toFixedCustom(staked, 2)
                          )
                        )
                        : 0} LP
                    </LabelText>
                  </div>
                  <InputDiv>
                    <InputStyled
                      value={amountUnstake}
                      placeholder="0"
                      type="number"
                      onChange={(e) =>
                        setAmountUnstake(Number(e.target.value) < 0 ? "0" : e.target.value)
                      }
                    />

                    <MaxButton onClick={() => setAmountUnstake(staked)}>
                      Max
                    </MaxButton>

                  </InputDiv>
                </div>
              </FormSection>
              {account ?
              <ButtonStyled onClick={handleCheckBeforeUnstake} disabled={disabledButtonUnstake}>
                {textButtonUnstake}
              </ButtonStyled> 
               : <ButtonStyled onClick={() => setShowConnectModal(true)}> CONNECT WALLET </ButtonStyled>}
            </>
            )}
          </ContentWrapper>
        </div>
        <div className="col-lg-8 col-md-12 mt-2">
          <Title style={{ marginLeft: '2rem' }}>
            REWARDS
          </Title>
          <RewardBox>
            <RewardItem>
              <img src={Shiboo} width={80} height={80} />
              <Title style={{ margin: '0.5rem 0' }}>
                <span className="claimable">Claimable:&nbsp;</span>
                {account ? toFixedCustom(shibooReward, 2) : 0}
              </Title>
              <ButtonStyled onClick={onClaimShiboo} disabled={!(Number(shibooReward) > 0)}>
                CLAIM
              </ButtonStyled>
            </RewardItem>
            <RewardItem>
              <img src={Casper} width={80} height={80} style={{ borderRadius: '99px' }} />
              <Title style={{ margin: '0.5rem 0' }}>
                <span className="claimable">Claimable:&nbsp;</span>
                {account ? toFixedCustom(csprReward, 2) : 0}
              </Title>
              <ButtonStyled onClick={onClaimCSPR} disabled={!(Number(csprReward) > 0)}>
                CLAIM
              </ButtonStyled>
              <ButtonStyled onClick={handleConfirmCompound} disabled={!(Number(csprReward) > 0)}>
                COMPOUND
              </ButtonStyled>
            </RewardItem>
          </RewardBox>
        </div>
      </SectionWrapper>

      {account && <><div className="mt-3">
        <Title style={{ marginLeft: '1rem' }}>
          USER DATA
        </Title>
        <ContentWrapper >
          <div
            style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '2rem' }}
          >
            <div className="d-flex flex-col align-items-center gap-2">
              <StatisticContent>{account ? toFixedCustom(staked, 2) : '-'} LP</StatisticContent>
              <StatisticContent>$ {account ? toFixedCustom(stakedUSD, 2) : '-'}</StatisticContent>
              <StatisticTitle>You staked</StatisticTitle>
            </div>
            <div className="d-flex flex-col align-items-center gap-2">
              <StatisticContent>{account ? (stakedShiboo && +stakedShiboo > 0 ? formatNumber(stakedShiboo) : 0) : '-'} Shiboo</StatisticContent>
              <StatisticContent>{account ? (stakedCSPR && +stakedCSPR > 0 ? formatNumber(stakedCSPR) : 0) : '-'} CSPR</StatisticContent>
              <StatisticTitle>You staked</StatisticTitle>
            </div>
            <div className="d-flex flex-col align-items-center gap-2">
              <StatisticContent>
                {account
                  ? (
                    loadingBalance ? (
                      <span>
                        <PulseLoader size={5} color="#00DC70" />
                      </span>
                    ) : (
                      toFixedCustom(balance, 2)
                    )
                  )
                  : '-'} LP
              </StatisticContent>
              <StatisticTitle>Available to stake</StatisticTitle>
            </div>

          </div>
        </ContentWrapper>
      </div>
      </>}
      <ColumnResponsive className="py-1 mt-3" style={{ borderTop: '1px solid #11292F' }}>
        <img src={LogoFooter} width={150} height={150} />
        <LinkSection>
          <ColumnResponsive>
            <div className="d-flex gap-3 flex-wrap">
              <Link href="https://shiboo.io/#roadmap" target="_blank">
                RoadMap
              </Link>
              <Link href="https://x.com/Shiboo_cspr/status/1839651250347909352" target="_blank">
                How to stake
              </Link>
              <Link href="https://shiboo-1.gitbook.io/shiboo-lp-staking" target="_blank">
                Docs
              </Link>
              <Link href="https://drive.google.com/file/d/1_PVnRlHZpAGlVTxYGneE7mW0brOA1EdL/view?usp=sharing" target="_blank">
                Thesis
              </Link>
              <Link href="https://shiboo.io/#faq" target="_blank">
                FAQ
              </Link>
            </div>
            <SocialBox>
              <a href="https://t.me/shibooinucspr" target="_blank">
                <FaTelegramPlane color="#fff" size={25} />
              </a>
              <div></div>
              <a href='https://x.com/shiboo_cspr' target="_blank">
                <FaXTwitter color="#fff" size={25} />
              </a>
            </SocialBox>
          </ColumnResponsive>
          <ColumnResponsive>
            <p style={{ fontFamily: 'Roboto Mono', fontWeight: '300', fontSize: '12px', color: '#fff', marginBottom: '-3rem' }}>
              Powered by:
            </p>
            <img src={CasperFooter} width={150} height={150} />
          </ColumnResponsive>
        </LinkSection>
        <p style={{ fontFamily: 'Roboto Mono', fontWeight: '300', fontSize: '12px', color: '#F4F4F4' }}>
          ©2024 SHIBOO. Our right to reach the Moon is reserved.
        </p>
      </ColumnResponsive>
      <PenaltyModal
        show={showPenaltyModal}
        penaltyRate={penaltyRate?.toString()}
        onConfirm={onUnstake}
        onHide={() => setShowPenaltyModal(false)}
      />
      <CompoundModal
        show={showCompoundModal}
        onHide={() => setShowCompoundModal(false)}
        onConfirm={onCompound}
      >
      </CompoundModal>
    </Column>
  );
}

export default StakePage;
